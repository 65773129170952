@charset "utf-8";
@use "variables/__sizes" as size;

#contents_ {
	
	> .c-article-body {
		> .top {margin:3.5rem 0 0;}
	}

	@include size.media-sp () {
		> header {
			> h1 {font-size:2.6rem;}
		}
		> .c-article-body {
			> .top {}
		}
	}

}