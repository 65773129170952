//Sizes
$min-width: 1280;
$mobile-width: 959;
$mobile-padding: 2.5rem;

@mixin media-pc () {
	@media screen and (min-width: $mobile-width + 1px) {
		@content;
	}
}

@mixin media-sp () {
	@media screen and (max-width: #{$mobile-width}px) {
		@content;
	}
}
